import React from "react";
// import { graphql } from "gatsby";


import Layout from "src/components/layout";
import SEO from "src/components/seo";
import Hero from "src/components/hero";
import Breadcrumb from "src/components/breadcrumb";
import MainHomepage from "src/components/main-homepage";
import MainBusiness from "src/components/main-business";
import MainPortInfo from "../components/main-port-info";
import MainEnvironment from "src/components/main-environment";
import MainCommission from "src/components/main-commission";
import MainCommunity from "src/components/main-community";

import 'babel-polyfill'

/**
 * Page template used by WordPress main pages
 * (main pages are Home, Commission, Community, and the other top-level pages)
 */
class LandingPageTemplate extends React.Component {
 
  state = {
    mainPageInfo: []
  }

  componentDidMount() {
    let requestOptions = {
      origin: "https://polb.com"
    };
    
    fetch(`https://thehelm.polb.com/wp-json/wp/v2/main-pages?slug=${this.props.pageContext.slug}`, requestOptions)
      .then(response => response.json())
      .then(json => {
        if (json.length > 0) {
          // console.log(json);
          this.setState({ mainPageInfo: json });
        }
      })
  }

  render() {
    const pageData = this.state.mainPageInfo[0];
    // const heroData = this.props.data.wordpressWpMainPages;

    return (
      <React.Fragment>
        {pageData ?
          <Layout hasHero>
            <SEO title={pageData.title.rendered} description="" />

            <Hero type="full" button={pageData.acf.button} buttonLabel={pageData.acf.button_label} category={pageData.title.rendered} headingLine1={pageData.acf.heading_line1} headingLine2={pageData.acf.heading_line2} heroBackgroundUrl={pageData.acf.hero_background} mobileHeroBackgroundUrl={pageData.acf.mobile_background} subheading={pageData.acf.subheading} hasVideo={pageData.slug === 'homepage' ? true : false} backgroundVideoId={pageData.acf.videoId} slug={pageData.slug} crisis={false} />

            { /* All main pages except Home have breadcrumb */
              pageData.slug !== 'homepage' &&
              <Breadcrumb categories={pageData.categories} pageTitle="Overview" />
            }

            { /* Specific to Homepage */
              pageData.slug === 'homepage' &&
              <React.Fragment>
                <MainHomepage pageData={pageData.acf} />
              </React.Fragment>
            }

            { /* Specific to Environment Main Page */
              pageData.slug === 'environment' &&
              <React.Fragment>
                <MainEnvironment pageData={pageData.acf} />
              </React.Fragment>
            }

            { /* Specific to Business Main Page */
              pageData.slug === 'business' &&
              <React.Fragment>
                <MainBusiness pageData={pageData.acf} />
              </React.Fragment>
            }

            { /* Specific to Port Info Main Page */
              pageData.slug === 'port-info' &&
              <React.Fragment>
                <MainPortInfo pageData={pageData.acf} />
              </React.Fragment>
            }

            { /* Specific to Commission */
              pageData.slug === 'commission' &&
              <React.Fragment>
                <MainCommission pageData={pageData.acf} />
              </React.Fragment>
            }

            { /* Specific to Community */
              pageData.slug === 'community' &&
              <React.Fragment>
                <MainCommunity pageData={pageData.acf} />
              </React.Fragment>
            }
          </Layout>
          :
          <div></div>
        }
      </React.Fragment>
    );
  }
}

export default LandingPageTemplate;

// export const pageQuery = graphql`
//   query currentWpMainPageQuery($id: String!) {
//     wordpressWpMainPages(id: { eq: $id }) {
//       id
//       acf {
//         hero_background {
//           localFile {
//             childImageSharp {
//               fluid(maxWidth: 1920) {
//                 ...GatsbyImageSharpFluid
//               }
//             }
//           }
//         }
//         mobile_background {
//           localFile {
//             childImageSharp {
//               fluid(maxWidth: 1920) {
//                 ...GatsbyImageSharpFluid
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;




























// import React from "react";
// import { graphql } from 'gatsby';

// import Layout from "src/components/layout";
// import SEO from "src/components/seo";
// import Hero from "src/components/hero";
// import Breadcrumb from "src/components/breadcrumb";
// import MainHomepage from "src/components/main-homepage";
// import MainBusiness from "src/components/main-business";
// import MainPortInfo from "../components/main-port-info";
// import MainEnvironment from "src/components/main-environment";
// import MainCommission from "src/components/main-commission";
// import MainCommunity from "src/components/main-community";

// /**
//  * Page template used by WordPress main pages
//  * (main pages are Home, Commission, Community, and the other top-level pages)
//  */
// class LandingPageTemplate extends React.Component {
//   render() {
//     const pageData = this.props.data.wordpressWpMainPages;

//     // Hardcode POC for Environment hero to show a specific Vimeo video as background
//     const backgroundVideoId = (pageData.slug !== 'environment') ? '' : '340781896';

//     return (
//       <Layout hasHero>
//         <SEO title="" description="" />

//         <Hero type="full" button={pageData.acf.button} buttonLabel={pageData.acf.button_label} category={pageData.title} headingLine1={pageData.acf.heading_line1} headingLine2={pageData.acf.heading_line2} heroBackgroundUrl={pageData.acf.hero_background} subheading={pageData.acf.subheading} hasVideo={pageData.slug === 'homepage' ? true : false } slug={pageData.slug} backgroundVideoId={backgroundVideoId} />

//         { /* All main pages except Home have breadcrumb */
//           pageData.slug !== 'homepage' &&
//           <Breadcrumb categories={[{name: pageData.title, slug: pageData.slug}]} pageTitle="Overview" />
//         }

//         { /* Specific to Homepage */
//           pageData.slug === 'homepage' &&
//           <React.Fragment>
//             <MainHomepage />
//           </React.Fragment>
//         }

//         { /* Specific to Environment Main Page */
//           pageData.slug === 'environment' &&
//           <React.Fragment>
//             <MainEnvironment />
//           </React.Fragment>
//         }

//         { /* Specific to Business Main Page */
//           pageData.slug === 'business' &&
//           <React.Fragment>
//             <MainBusiness />
//           </React.Fragment>
//         }

//         { /* Specific to Port Info Main Page */
//           pageData.slug === 'port-info' &&
//           <React.Fragment>
//             <MainPortInfo />
//           </React.Fragment>
//         }

//         { /* Specific to Commission */
//           pageData.slug === 'commission' &&
//           <React.Fragment>
//             <MainCommission />
//           </React.Fragment>
//         }

//         { /* Specific to Community */
//           pageData.slug === 'community' &&
//           <React.Fragment>
//             <MainCommunity />
//           </React.Fragment>
//         }
//       </Layout>
//     );
//   }
// }

// export default LandingPageTemplate;

// export const pageQuery = graphql`
//   query currentWpMainPageQuery($id: String!) {
//     wordpressWpMainPages(id: { eq: $id }) {
//       id
//       slug
//       title
//       acf {
//         heading_line1
//         heading_line2
//         subheading
//         button
//         button_label
//         hero_background {
//           localFile {
//             childImageSharp {
//               fluid(maxWidth: 1920) {
//                 ...GatsbyImageSharpFluid
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;
